/* eslint-disable jsx-a11y/anchor-is-valid */
import { Outlet, Route, Routes } from 'react-router-dom'
import { PageTitle } from '../../_metronic/layout/core'
import { TemplateListWrapper } from '../modules/templates/TemplatesList'

const Templates = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/'
          element={
            <>
              <PageTitle>Modelos</PageTitle>
              <TemplateListWrapper />
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export default Templates
