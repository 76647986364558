/* eslint-disable jsx-a11y/anchor-is-valid */
import {PageTitle} from '../../_metronic/layout/core'
import { Outlet, Route, Routes } from 'react-router-dom'
import { DocumentosListWrapper } from '../modules/documentos-gerados/List'

function DocumentosWrapper() {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/'
          element={
            <>
              <PageTitle>Contratos Gerados</PageTitle>
              <DocumentosListWrapper />
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export {DocumentosWrapper}
