import {FC} from 'react'

type Props = {
  status?: boolean
  descricao?: string
}

const StatusCell: FC<Props> = ({status, descricao}) => (
  <>
    {status && <div className='badge badge-light-success fw-bolder'>Sim</div>}
    {!status && <div className='badge badge-light-danger fw-bolder'>Não</div>}
  </>
)

export {StatusCell}
