// @ts-nocheck
import {Column} from 'react-table'
import {ColaboradorStatusCell} from './ColaboradorStatusCell'
import {ColaboradorActionsCell} from './ColaboradorActionsCell'
import {ColaboradorSelectionCell} from './ColaboradorSelectionCell'
import {ColaboradorCustomHeader} from './ColaboradorCustomHeader'
import {ColaboradorSelectionHeader} from './ColaboradorSelectionHeader'
import {Colaborador} from '../../core/_models'

const colaboradoresColumns: ReadonlyArray<Column<Colaborador>> = [
  {
    Header: (props) => <ColaboradorSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <ColaboradorSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => (
      <ColaboradorCustomHeader tableProps={props} title='Nome' className='min-w-125px' />
    ),
    accessor: 'nome',
  },
  {
    Header: (props) => (
      <ColaboradorCustomHeader tableProps={props} title='Perfil' className='min-w-125px' />
    ),
    accessor: 'perfil.nome',
  },
  {
    Header: (props) => (
      <ColaboradorCustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    id: 'status',
    Cell: ({...props}) => <ColaboradorStatusCell status={props.data[props.row.index].status} />,
  },
  {
    Header: (props) => (
      <ColaboradorCustomHeader tableProps={props} title='Ações' className='text-end min-w-100px' />
    ),
    id: 'acoes',
    Cell: ({...props}) => <ColaboradorActionsCell id={props.data[props.row.index].id} />,
  },
]

export {colaboradoresColumns}
