import {useQuery} from '@tanstack/react-query'
import {EditModalForm} from './EditModalForm'
import {isNotEmpty, QUERIES} from '../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import {buscarPerfil, buscarTodasPermissoes} from '../core/_requests'
import {useAuth} from '../../auth'
import {Perfil, Permissao} from '../core/_models'

export function EditModalFormWrapper() {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {currentUser} = useAuth()
  const id_organizacao = currentUser!.id_organizacao

  function onError(err: Error) {
    setItemIdForUpdate(undefined)
    console.error(err)
  }

  const formQuery = useQuery(
    [`${QUERIES.PERFIL_LIST}-perfil-${itemIdForUpdate}`],
    () => {
      return buscarPerfil(itemIdForUpdate)
    },
    {
      enabled: enabledQuery,
      onError,
    }
  )

  const permissoesQuery = useQuery(
    [`${QUERIES.PERFIL_TODOS}-permissoes-${id_organizacao}`],
    async () => {
      const todos = await buscarTodasPermissoes()
      return todos.reduce((prev: {[k: string]: Permissao[]}, curr: Permissao, currIdx, arr) => {
        const permissao = [...(prev[curr.tipo_permissao] ?? []), curr]
        return {...prev, [curr.tipo_permissao]: permissao}
      }, {})
    },
    {
      onError,
    }
  )

  const isLoading = formQuery.isLoading && permissoesQuery.isLoading // && permissoesQuery.isLoading
  const error = formQuery.error && permissoesQuery.error // && permissoesQuery.error

  const perfil = formQuery.data
  const permissoes = permissoesQuery.data

  if (!itemIdForUpdate) {
    return (
      <EditModalForm
        isLoading={isLoading}
        perfil={{id_organizacao} as Perfil}
        permissoes={permissoes}
      />
    )
  }

  if (!isLoading && !error && perfil) {
    return (
      <EditModalForm
        isLoading={isLoading}
        perfil={{id: itemIdForUpdate, ...perfil, id_organizacao}}
        permissoes={permissoes}
      />
    )
  }

  return null
}
