/* eslint-disable react-hooks/exhaustive-deps */
import {FC} from 'react'
import {
  QUERIES,
  WithChildren,
} from '../../../../_metronic/helpers'
import {buscarTemplates} from './_requests'
import {useAuth} from '../../auth'
import { PageableQueryResponseProvider } from '../../../components/pageable/query-response.provider'

const QueryResponseProvider: FC<WithChildren> = ({children}) => {
  const auth = useAuth()

  const id_requerente = auth.currentUser!.id
  const id_organizacao = auth.currentUser!.id_organizacao
  const query = `${QUERIES.TEMPLATES_LIST}-${id_requerente}-${id_organizacao}`;

  return (
    <PageableQueryResponseProvider
      query={query}
      queryFunction={buscarTemplates}
    >
      {children}
    </PageableQueryResponseProvider>
  )
}


export {
  QueryResponseProvider,
}
