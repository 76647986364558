/* eslint-disable jsx-a11y/anchor-is-valid */
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { enqueueSnackbar } from 'notistack'
import { FC, useEffect, useState } from 'react'
import { MenuComponent } from '../../../../../_metronic/assets/ts/components'
import { ID, KTSVG } from '../../../../../_metronic/helpers'
import { useListView } from '../../core/ListViewProvider'
import { useQueryResponse } from '../../../../components/pageable/query-response.provider'
import { deletarColaborador } from '../../core/_requests'
import { DeleteModal } from '../../../../components/delete-modal'

type Props = {
  id: ID
}

const ColaboradorActionsCell: FC<Props> = ({id}) => {
  const {setItemIdForUpdate} = useListView()
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }
  const [confirmModal, setConfirmModal] = useState(false)

  const deleteItem = useMutation(() => deletarColaborador(id), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([query])
      setConfirmModal(false)
    },
    onError: (e:any) => {
      enqueueSnackbar(e.response.data.message, {variant: 'error'})
      setConfirmModal(false)
    }
  })


  function showConfirmModal() {
    setConfirmModal(true)
  }

  async function onConfirm() {
    await deleteItem.mutateAsync()
  }
  async function onCancel() {
    setConfirmModal(false)
  }

  return (
    <>
      {/* begin::Menu */}
      <div className='d-flex justify-content-end flex-shrink-0'>
        <a
          href='#'
          title='Editar'
          onClick={openEditModal}
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        >
          <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
        </a>
        <a
          href='#'
          title='Deletar'
          onClick={showConfirmModal}
          data-kt-colaboradores-table-filter='delete_row'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen027.svg'
            className='svg-icon-3'
          />
        </a>
      </div>

      {/* end::Menu */}
      {confirmModal && (
        <DeleteModal
          cancelAction={onCancel}
          confirmAction={onConfirm}
          message={`o colaborador`}
        />
      )}
    </>
  )
}

export { ColaboradorActionsCell }

