/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {MenuComponent} from '../../../../../_metronic/assets/ts/components'
import {KTSVG} from '../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../../../components/pageable/query-response.provider'
import {deletarPerfil, tooglePerfil} from '../../core/_requests'
import {enqueueSnackbar} from 'notistack'
import { Perfil } from '../../core/_models'
import { DeleteModal } from '../../../../components/delete-modal'

export type Props = {item: Perfil};

const ActionsCell: FC<Props> = ({item}) => {
  const {setItemIdForUpdate} = useListView()
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()
  const [showError, setError] = useState<string | undefined>(undefined)

  const {id, ativo, master} = item

  const visualAtivo = ativo
    ? 'btn btn-icon btn-bg-light btn-color-primary btn-sm me-1'
    : 'btn btn-icon btn-bg-light btn-color-secondary btn-sm me-1'

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }

  const toggleItem = useMutation(() => tooglePerfil(id), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      setError(undefined)
      queryClient.invalidateQueries([query])
    },
    onError: (e: any) => {
      enqueueSnackbar(e.response.data.message, {variant: 'error'})
    },
  })

  const deleteItem = useMutation(() => deletarPerfil(id), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([query])
    },
    onError: (e: any) => {
      setError(e.response.data.message)
    },
  })

  const [confirmModal, setConfirmModal] = useState(false)

  function showConfirmModal() {
    setConfirmModal(true)
  }

  async function onConfirmToggle() {
    await toggleItem.mutateAsync()
  }

  async function onConfirm() {
    await deleteItem.mutateAsync()
    setConfirmModal(false)
  }
  async function onCancel() {
    setConfirmModal(false)
  }

  return (
    <>
      {!master && (
        <div className='d-flex justify-content-end flex-shrink-0'>
          <a href='#' title='Ativar/Desativar' onClick={onConfirmToggle} className={visualAtivo}>
            <KTSVG path='/media/icons/duotune/general/gen019.svg' className='svg-icon-3' />
          </a>

          <a
            href='#'
            title='Editar'
            onClick={openEditModal}
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          >
            <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
          </a>
          <a
            href='#'
            title='Deletar'
            onClick={showConfirmModal}
            data-kt-perfis-table-filter='delete_row'
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
          >
            <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
          </a>
        </div>
      )}

      {/* end::Menu */}
      {confirmModal && (
        <DeleteModal
          cancelAction={onCancel}
          confirmAction={onConfirm}
          message={`o perfil`}
          showError={!!showError}
          errorMessage={showError}
        />
      )}
    </>
  )
}

export {ActionsCell}
