import {useQueryClient} from '@tanstack/react-query'
import React, {useEffect} from 'react'

export type Props = {
  cancelAction: VoidFunction
  confirmAction: VoidFunction
  message: string
  showError?: boolean
  errorMessage?: string | React.ReactNode
}

export function DeleteModal({
  cancelAction,
  confirmAction,
  message,
  showError,
  errorMessage = 'Houve um error ao excluir',
}: Props) {
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  const queryClient = useQueryClient()
  const isLoading = queryClient.isFetching() > 0 || queryClient.isMutating() > 0

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_modal'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            {/* begin::Modal body */}
            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7' style={{display: 'flex'}}>
              Deseja excluir {message}?
            </div>
            {showError && (
              <div className='alert alert-danger d-flex align-items-center p-5 m-5'>
                {errorMessage}
              </div>
            )}
            <div className='modal-footer'>
              {isLoading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
              <button
                type='button'
                className='btn btn-light'
                data-bs-dismiss='modal'
                onClick={cancelAction}
                disabled={isLoading}
              >
                Cancelar
              </button>
              <button
                type='button'
                className='btn btn-primary'
                onClick={confirmAction}
                disabled={isLoading}
              >
                Confirmar
              </button>
            </div>

            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}
