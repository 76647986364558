import {useQuery} from '@tanstack/react-query'
import {EditModalForm} from './EditModalForm'
import {isNotEmpty, QUERIES} from '../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import {buscarDocumentos} from '../core/_requests'
import {Documentos} from '../core/_models'
import {buscarTemplates} from '../../templates/core/_requests'

export function EditModalFormWrapper() {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)

  function onError(err: Error) {
    setItemIdForUpdate(undefined)
    console.error(err)
  }

  const formQuery = useQuery(
    [`${QUERIES.DOCUMENTOS_LIST}-documentos-${itemIdForUpdate}`],
    () => {
      return buscarDocumentos(itemIdForUpdate)
    },
    {
      enabled: enabledQuery,
      onError,
    }
  )

  const templatesQuery = useQuery(
    [`${QUERIES.DOCUMENTOS_TODOS}-templates`],
    () => {
      return buscarTemplates({size: 1000}) // TODO: usar o select async do react-select
    },
    {onError}
  )

  const isLoading = formQuery.isLoading && templatesQuery.isLoading
  const error = formQuery.error && templatesQuery.error

  const documentos = formQuery.data
  const templates = templatesQuery.data?.data?.itens

  if (!itemIdForUpdate) {
    return (
      <EditModalForm isLoading={isLoading} documentos={{} as Documentos} templates={templates} />
    )
  }

  if (!isLoading && !error && documentos) {
    return (
      <EditModalForm
        isLoading={isLoading}
        documentos={{id: itemIdForUpdate, ...documentos}}
        templates={templates}
      />
    )
  }

  return null
}
