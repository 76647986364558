import React from 'react'
import {validateLogin} from '../core/_requests'
import {useAuth} from '../core/Auth'
import {getAuth as getFirebaseAuth} from 'firebase/auth'
import {setAuth} from '../core/AuthHelpers'
import {useNavigate} from 'react-router-dom'

function DoLogin() {
  const {setCurrentUser} = useAuth()
  const navigate = useNavigate()

  React.useEffect(
    () => {
      async function validate() {
        try {
          const auth = getFirebaseAuth()
          if (auth.currentUser) {
            try {
              const firebaseToken = await auth.currentUser.getIdToken(true)
              if (firebaseToken) {
                setAuth({
                  api_token: firebaseToken,
                })
                const data = await validateLogin(firebaseToken).catch((error) => {
                  if (
                    error.response.status === 401 &&
                    error.response.data.message === 'Usuário não cadastrado'
                  ) {
                    return null;
                  }
                })

                if (data){
                  setCurrentUser(data)
                   // Decode the token to check claims
                  const decodedToken = await auth.currentUser.getIdTokenResult()
                  const claims = decodedToken.claims
                  // Check if the required claims exist and are null
                  if (!claims.idOrganizacao || !claims.idUsuario) {
                    // Force token refresh to get updated claims
                      await auth.currentUser.getIdToken(true)
                      // Get new token with updated claims
                      const newFirebaseToken = await auth.currentUser.getIdToken()
                      // Update auth with new token
                      setAuth({
                        api_token: newFirebaseToken,
                      })
                  }
                }else {
                  navigate('/auth/registration')
                } 
              }
            } catch {
              navigate('/auth')
            }
          } else {
            navigate('/auth')
          }
        } catch {
          navigate('/auth')
        }
      }
      validate()
    },
    [
      navigate, setCurrentUser
    ]
  )

  return (
    <span className='indicator-progress' style={{display: 'block'}}>
      Por favor aguarde...
      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
    </span>
  )
}

export default DoLogin
