import {useListView} from '../../core/ListViewProvider'
import {ColaboradorListToolbar} from './ColaboradorListToolbar'
import {ColaboradoresListGrouping} from './ColaboradoresListGrouping'
import {ColaboradoresListSearchComponent} from './ColaboradoresListSearchComponent'

const ColaboradoresListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <ColaboradoresListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <ColaboradoresListGrouping /> : <ColaboradorListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {ColaboradoresListHeader}
