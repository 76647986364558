import {useMemo} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from '../table/columns/CustomHeaderColumn'
import {CustomRow} from '../table/columns/CustomRow'
import {useQueryResponseData, useQueryResponseLoading} from '../../../components/pageable/query-response.provider'
import {colaboradoresColumns} from './columns/_columns'
import {Colaborador} from '../core/_models'
import {ColaboradoresListPagination} from '../components/pagination/ColaboradoresListPagination'
import {KTCardBody} from '../../../../_metronic/helpers'
import { ListLoading } from '../../../components/ListLoading'

const ColaboradoresTable = () => {
  const colaboradores = useQueryResponseData<Colaborador>()

  const isLoading = useQueryResponseLoading()
  const data = useMemo(
    () => colaboradores.sort((a, b) => (b.id ?? 0) - (a.id ?? 0)),
    [colaboradores]
  )
  const columns = useMemo(() => colaboradoresColumns, [])

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_colaboradores'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<Colaborador>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<Colaborador>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    Nenhum registro encontrado
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <ColaboradoresListPagination />
      {isLoading && <ListLoading />}
    </KTCardBody>
  )
}

export {ColaboradoresTable}
