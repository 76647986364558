import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBhj6ruST6itbUpnB5IzRt2Z51yJLjdrjY",
  authDomain: "todoc-5842f.firebaseapp.com",
  projectId: "todoc-5842f",
  storageBucket: "todoc-5842f.appspot.com",
  messagingSenderId: "624992165487",
  appId: "1:624992165487:web:d96d12f32ddec563a7779a",
  measurementId: "G-86G0603X2H"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

export const firebaseAppAuthentication = getAuth(app);
// const analytics = getAnalytics(app);