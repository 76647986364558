/* eslint-disable jsx-a11y/anchor-is-valid */
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {FC, useEffect, useState} from 'react'
import {MenuComponent} from '../../../../../_metronic/assets/ts/components'
import {ID, KTSVG} from '../../../../../_metronic/helpers'
import {deletarTemplate} from '../../core/_requests'
import {DeleteModal} from '../../../../components/delete-modal'
import {useQueryResponse} from '../../../../components/pageable/query-response.provider'

type Props = {
  id: ID
}

const TemplateActionsCell: FC<Props> = ({id}) => {
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const [confirmModal, setConfirmModal] = useState(false)
  const [showError, setError] = useState<string | undefined>(undefined)

  const deleteItem = useMutation(() => deletarTemplate(id), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([query])
      setConfirmModal(false)
    },
    onError: (err: any) => {
      setError(err.response.data.message ?? 'Houve um error ao excluir')
    },
  })

  function showConfirmModal() {
    setConfirmModal(true)
  }

  async function onConfirm() {
    setError(undefined)
    await deleteItem.mutateAsync()
  }
  async function onCancel() {
    setError(undefined)
    setConfirmModal(false)
  }

  return (
    <>
      {/* begin::Menu */}
      <div className='d-flex justify-content-end flex-shrink-0'>
        {/* <a
          href='#'
          title='Editar'
          onClick={openEditModal}
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        >
          <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
        </a> */}
        <a
          href='#'
          title='Deletar'
          onClick={showConfirmModal}
          data-kt-templatees-table-filter='delete_row'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
        >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </a>
      </div>

      {/* end::Menu */}
      {confirmModal && (
        <DeleteModal
          cancelAction={onCancel}
          confirmAction={onConfirm}
          message='o template'
          showError={!!showError}
          errorMessage={showError}
        />
      )}
    </>
  )
}

export {TemplateActionsCell}
