import {useState, createContext, useEffect, FC} from 'react'
import {getAuth, signOut as googleSignOut, GoogleAuthProvider, signInWithPopup, User, UserCredential} from 'firebase/auth'
import {app} from '../services/firebaseConfig'
import {WithChildren} from '../../_metronic/helpers'

const provider = new GoogleAuthProvider()

export type AuthGoogleType = {
  signed: boolean
  user: User | null | undefined
  signInGoogle: () => Promise<UserCredential>
  signOut: () => Promise<void>
}

export const AuthGoogleContext = createContext<AuthGoogleType>({} as AuthGoogleType)

export const AuthGoogleProvider: FC<WithChildren> = ({children}) => {
  const auth = getAuth(app)
  const [user, setUser] = useState<User | null>(null)

  useEffect(() => {
    const loadStorageData = () => {
      const storageUser = sessionStorage.getItem('@AuthFirebase:user')
      const storageToken = sessionStorage.getItem('@AuthFirebase:token')
      if (storageToken && storageUser) {
        setUser(JSON.parse(storageUser))
      }
    }
    loadStorageData()
  })

  function signInGoogle() {
    return signInWithPopup(auth, provider)
      .then((result: any) => {
        const credential = GoogleAuthProvider.credentialFromResult(result)
        const token = credential?.accessToken
        const user = result.user
        if (user) {
          setUser(user)
          sessionStorage.setItem('@AuthFirebase:token', token || '')
          sessionStorage.setItem('@AuthFirebase:user', JSON.stringify(user))
        }
        return result;
      })
      .catch((error: any) => {
        const errorCode = error.code
        const errorMessage = error.message
        const email = error.email
        const credential = GoogleAuthProvider.credentialFromError(error)
        console.log('AuthGoogleProvider', errorCode, errorMessage, email, credential)
        return error;
      })
  }

  async function signOut() {
    await googleSignOut(auth);
    sessionStorage.clear()
    setUser(null)
  }

  return (
    <AuthGoogleContext.Provider
      value={{
        signed: !!user,
        user,
        signInGoogle,
        signOut,
      }}
    >
      {children}
    </AuthGoogleContext.Provider>
  )
}
