import {useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty} from '../../../../_metronic/helpers'
import {Perfil, Permissao} from '../core/_models'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {atualizarPerfil, criarPerfil} from '../core/_requests'
import {useQueryResponse} from '../../../components/pageable/query-response.provider'
import {enqueueSnackbar} from 'notistack'
import {ListLoading} from '../../../components/ListLoading'

type Props = {
  isLoading: boolean
  perfil: Perfil
  permissoes?: {[k: string]: Permissao[]}
}

const editUserSchema = Yup.object().shape({
  nome: Yup.string()
    .min(3, 'Mínimo de 3 caracteres')
    .max(50, 'Máximo de 50 caracteres')
    .required('Nome é obrigatório'),
  descricao: Yup.string().min(3, 'Mínimo de 3 caracteres').max(250, 'Máximo de 250 caracteres'),
  permissoes: Yup.array(),
})

export function EditModalForm({perfil, permissoes, isLoading}: Props) {
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [error, setError] = useState<string | undefined>()

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: {
      ...perfil,
      id_organizacao: perfil?.id_organizacao ?? 0,
      ativo: perfil?.ativo === undefined ? true : perfil?.ativo,
      permissoes: perfil?.permissoes?.map((p) => JSON.stringify(p)),
    },
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      setError(undefined)
      try {
        const data = {
          ...values,
          permissoes: values.permissoes?.map((p) => JSON.parse(p) as Permissao),
        }
        if (isNotEmpty(values.id) && values.id !== 0) {
          await atualizarPerfil(data)
          enqueueSnackbar('Perfil atualizado com sucesso', {variant: 'info'})
        } else {
          await criarPerfil(data)
          enqueueSnackbar('Perfil salvo com sucesso', {variant: 'info'})
        }
        cancel(true)
      } catch (ex) {
        setError((ex as any)?.response?.data?.message || (ex as string))
        console.error(ex)
      } finally {
        setSubmitting(false)
      }
    },
  })

  return (
    <>
      <form
        id='kt_modal_add_colaborador_form'
        className='form'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y'
          id='kt_modal_add_colaborador_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_colaborador_header'
          data-kt-scroll-wrappers='#kt_modal_add_colaborador_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}
          <div className='fv-row mb-3'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Nome do Perfil</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Nome'
              {...formik.getFieldProps('nome')}
              type='text'
              name='nome'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.nome && formik.errors.nome},
                {
                  'is-valid': formik.touched.nome && !formik.errors.nome,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isLoading}
            />
            {formik.touched.nome && formik.errors.nome && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.nome}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='fw-bold fs-6 mb-2'>Descrição do perfil</label>
            {/* end::Label */}

            {/* begin::Input */}
            <textarea
              placeholder='Descrição'
              {...formik.getFieldProps('descricao')}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.descricao && formik.errors.descricao},
                {
                  'is-valid': formik.touched.descricao && !formik.errors.descricao,
                }
              )}
              name='descricao'
              autoComplete='off'
              disabled={formik.isSubmitting || isLoading}
            />
            {/* end::Input */}
            {formik.touched.descricao && formik.errors.descricao && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.descricao}</span>
              </div>
            )}
          </div>
          {/* end::Input group */}

          {!perfil.master && (
            <div className='mb-7'>
              {/* begin::Label */}
              {/* end::Label */}
              {/* begin::Roles */}
              {/* begin::Input row */}
              <div className='d-flex flex-column'>
                <div className='form-check form-switch form-check-custom form-check-solid'>
                  <input
                    className='form-check-input w-45px h-30px'
                    type='checkbox'
                    {...formik.getFieldProps('ativo')}
                    name='ativo'
                    defaultChecked
                  />
                  <label className='form-check-label'>Ativo</label>
                </div>
              </div>
            </div>
          )}

          {/* begin::Input group */}
          <div className='mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-5'>Permissões</label>
            {/* end::Label */}
            {/* begin::Roles */}
            {/* begin::Input row */}
            <div className='d-flex flex-column'>
              {permissoes && (
                <>
                  {Object.keys(permissoes).map((k) => (
                    <div key={k} className='mb-5'>
                      {/* begin::Radio */}
                      <b className='text-capitalize'>{k.toLowerCase()}</b>
                      <div className='d-flex flex-row flex-wrap gap-3' >
                        {permissoes[k].map((p) => (
                          <div
                            key={p.id}
                            className='form-check form-check-custom form-check-solid mt-3'
                          >
                            <input
                              className='form-check-input'
                              type='checkbox'
                              {...formik.getFieldProps('permissoes')}
                              value={JSON.stringify(p)}
                              name='permissoes'
                              defaultChecked={
                                !!perfil?.permissoes
                                  ?.map((perm: any) =>
                                    perm instanceof Object ? perm : JSON.parse(perm)
                                  )
                                  .find(
                                    (perm: Permissao) =>
                                      perm.id === p.id && perm.tipo_permissao === p.tipo_permissao
                                  )
                              }
                            />
                            <label className='form-check-label'>{p.nome}</label>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
            {/* end::Input row */}
            <div className='separator separator-dashed my-2'></div>
            {/* begin::Input row */}
            {/* end::Input row */}
            {/* end::Roles */}
          </div>

          {/* end::Input group */}
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-5'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-colaboradores-modal-action='cancel'
            disabled={formik.isSubmitting || isLoading}
          >
            Cancelar
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-colaboradores-modal-action='submit'
            disabled={isLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Salvar</span>
            {(formik.isSubmitting || isLoading) && (
              <span className='indicator-progress'>
                Por favor aguarde...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          {error && (
            <div className='alert alert-danger d-flex align-items-center p-5 m-5'>
              Houve algum erro, {error}
            </div>
          )}
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isLoading) && <ListLoading />}
    </>
  )
}
