
export type Atributo = {
    descricao: string
    incluso: boolean
};

export type Plano = {
    id: number
    titulo: string
    descricao: string
    preco: number
    atributos: Atributo[]
};

export type Duracao = {
    [key: string]: Plano[]
}

export default function AssinaturaPage() {
    return <>Assinatura</>
}