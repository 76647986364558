import axios from 'axios'
import {ID} from '../../../../_metronic/helpers'
import {Documentos, DocumentosQueryResponse} from './_models'
import {Pageable} from '../../../components/pageable/pageable.interface'
import {enqueueSnackbar} from 'notistack'

const API_URL = process.env.REACT_APP_API_BACK_TODOC
const EXECUCOES_URL = `${API_URL}/execucoes`
const CONTRATOS_URL = `${API_URL}/contratos`

function buscarDocumentos(id: ID) {
  return axios
    .get<Documentos>(`${EXECUCOES_URL}`, {
      params: {
        id_documentos: id,
      },
    })
    .then((d) => d.data)
}

const buscarTodosDocumentos = (params?: Pageable): Promise<DocumentosQueryResponse> => {
  return axios.get(`${EXECUCOES_URL}/todos`, {params})
}

const deletarDocumento = (id: ID): Promise<any> => {
  return deletarVariosDocumentos([id])
}

function deletarVariosDocumentos(ids: ID[]) {
  return axios.delete(`${EXECUCOES_URL}`, {
    data: ids,
  })
}

function atualizarDocumentos(documentos: Documentos) {
  return axios.post(`${EXECUCOES_URL}`, documentos)
}
// function gerarDocumentos(documentos: Documentos) {
//   return axios.post(`${DOCUMENTOS_URL}/gerar`, {}, {params: {...documentos, cnpjs: documentos.cnpjs.join('%2C')}})
// }

/** Fallback caso o gerar documentos acima não funcionar */
function gerarDocumentos(documentos: Documentos) {
  // const urlParams = new URLSearchParams()
  // urlParams.append('cnpjs', documentos.cnpjs.join(','))
  // //documentos.cnpjs.forEach(element => urlParams.append('cnpjs', element));
  // urlParams.append('id_template', `${documentos.id_template}`)
  // documentos.descricao && urlParams.append('descricao', documentos.descricao)
  return axios.post(`${CONTRATOS_URL}/gerar`, documentos)
}

function enviarDocumentos(id: ID, mome_cliente: string, email: string) {
  return axios.post(`${CONTRATOS_URL}/enviar`, {id, mome_cliente, email})
}

async function downloadDocumento(id: ID): Promise<void> {
  try {
    const response = await axios.get(`${CONTRATOS_URL}/download`, {
      params: {arquivo_id: id},
    })

    if (response.data.file_name.endsWith('.pdf')) {
      // Open in new window/tab
      window.open(response.data.link, '_blank')
    } else {
      // Create an invisible anchor element
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = response.data.link
      // If you want to force download instead of opening in browser
      a.setAttribute('download', response.data.file_name || '')

      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    }

    enqueueSnackbar('Download realizado com sucesso!', {variant: 'info'})
  } catch (error) {
    handleDownloadError(error)
  }
}

function handleDownloadError(error: any): void {
  console.error('Download error:', error)
  enqueueSnackbar('Falha no Download.', {variant: 'error'})
  // throw error; // Optional: rethrow the error if needed for further handling
}

export {
  atualizarDocumentos,
  buscarDocumentos,
  buscarTodosDocumentos,
  gerarDocumentos,
  enviarDocumentos,
  deletarDocumento,
  deletarVariosDocumentos,
  downloadDocumento,
}
