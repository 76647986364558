import {useMutation, useQueryClient} from '@tanstack/react-query'
import { enqueueSnackbar } from 'notistack'
import {useState} from 'react'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../../../components/pageable/query-response.provider'
import {deletarColaboradoresSelecionados} from '../../core/_requests'
import { DeleteModal } from '../../../../components/delete-modal'

const ColaboradoresListGrouping = () => {
  const {selected, clearSelected} = useListView()
  const queryClient = useQueryClient()
  const {query} = useQueryResponse()

  const [confirmModal, setConfirmModal] = useState(false)

  const deleteSelectedItems = useMutation(() => deletarColaboradoresSelecionados(selected), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([query])
      // queryClient.refetchQueries();
      clearSelected()
      setConfirmModal(false)
    },
    onError: (e: any) => {
      enqueueSnackbar(e.response.data.message, {variant: 'error'})
      setConfirmModal(false)
    },
  })


  function showConfirmModal() {
    setConfirmModal(true)
  }

  async function onConfirm() {
    await deleteSelectedItems.mutateAsync()
  }
  async function onCancel() {
    setConfirmModal(false)
  }

  return (
    <div className='d-flex justify-content-end align-items-center'>
      <div className='fw-bolder me-5'>
        <span className='me-2'>{selected.length}</span>
        {selected.length === 1 ? 'Selecionado' : 'Selecionados'}
      </div>

      <button type='button' className='btn btn-danger' onClick={showConfirmModal}>
        Excluir {selected.length === 1 ? 'Selecionado' : 'Selecionados'}
      </button>
      {confirmModal && (
        <DeleteModal
          cancelAction={onCancel}
          confirmAction={onConfirm}
          message={`o(s) ${selected.length} colaborador(es) selecionado(s)`}
        />
      )}
    </div>
  )
}

export {ColaboradoresListGrouping}
