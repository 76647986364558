import React from 'react'
import {useDropzone} from 'react-dropzone'

export function UploadComponent({
  maxFiles = 1,
  onDrop,
  accept = {
    'application/msword': ['.doc'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    // 'application/pdf': ['.pdf'],
  },
  ...props
}: {
  maxFiles?: number
  accept?: {[k: string]: string[]}
} & {[k: string]: any}) {
  const {getRootProps, getInputProps, isDragActive, isDragReject, acceptedFiles} = useDropzone({
    onDrop,
    maxFiles,
    accept,
  })

  return (
    <div {...getRootProps({className: 'dropzone'})}>
      <input {...getInputProps()} />
      <i className='fas fa-file-upload text-dark'></i>
      {isDragActive ? (
        <p className='fw-bold'>Arraste seus arquivos...</p>
      ) : (
        <p className='fw-bold'>Clique aqui ou arraste seus arquivos...</p>
      )}
      {isDragReject && <p className='fw-bold'>Arquivo não suportado...</p>}
      {acceptedFiles?.length > 0 && (
        <aside>
          <h4>Arquivo selecionado</h4>
          {acceptedFiles.map((file: File) => (
            <p key={file.name}>
              {file.name} - {file.size} bytes
            </p>
          ))}
        </aside>
      )}
    </div>
  )
}
