const QUERIES = {
  USERS_LIST: 'users-list',
  COLABORADORES_LIST: 'colaboradores-list',
  PERFIL_LIST: 'PERFIL_LIST',
  PERFIL_TODOS: 'PERFIL_TODOS',
  PERMISSAO_LIST: 'PERMISSAO_LIST',
  TEMPLATES_LIST: 'TEMPLATES_LIST',
  DOCUMENTOS_TODOS: 'DOCUMENTOS_TODOS',
  DOCUMENTOS_LIST: 'DOCUMENTOS_LIST'
}

export {QUERIES}
