/* eslint-disable jsx-a11y/anchor-is-valid */
import {Outlet, Route, Routes} from 'react-router-dom'
import AssinaturaPage from '../modules/assinaturas/assinatura-page'

export default function Assinatura() {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/'
          element={
            <>
              <AssinaturaPage />
            </>
          }
        />
      </Route>
    </Routes>
  )
}
