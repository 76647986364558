import {FC} from 'react'
import {useAuth} from '../../../auth'
import {downloadDocumento} from '../../core/_requests'
import { Arquivo } from '../../core/_models'

type Props = {
  arquivo_doc:Arquivo
  arquivo_pdf:Arquivo
}

const ExecucaoDownloadCell: FC<Props> = ({arquivo_doc, arquivo_pdf}) => {
  const {hasPermission} = useAuth()
  const docxDownload = hasPermission([2])
  const pdfDownload = hasPermission([3])

  return (
    <>
      <div className='d-flex flex-row w-100px'>
        {docxDownload && (
          <div className='d-flex flex-column-fluid'>
            <button
              onClick={(e) => {
                e.preventDefault()
                downloadDocumento(arquivo_doc.id)
              }}
              className='btn btn-icon btn-primary'
              title='Download DOCX'
            >
              <i className='bi bi-filetype-docx fs-2x'></i>
            </button>
          </div>
        )}
        {pdfDownload && (
          <div className='d-flex flex-column-fluid'>
            <button
              onClick={(e) => {
                e.preventDefault()
                downloadDocumento(arquivo_pdf.id)
              }}
              className='btn btn-icon btn-primary'
              title='Download PDF'
            >
              <i className='bi bi-filetype-pdf fs-2x'></i>
            </button>
          </div>
        )}
      </div>
    </>
  )
}

export {ExecucaoDownloadCell}
