// @ts-nocheck
import {Column} from 'react-table'
import {TemplateDownloadCell} from './TemplateDownloadCell'
import {TemplateActionsCell} from './TemplateActionsCell'
import {TemplateSelectionCell} from './TemplateSelectionCell'
import {TemplateCustomHeader} from './TemplateCustomHeader'
import {TemplateSelectionHeader} from './TemplateSelectionHeader'
import {Template} from '../../core/_models'
import { TemplateCamposCustomizadosCell } from './TemplateCamposCustomizadosCell'

const templateesColumns: ReadonlyArray<Column<Template>> = [
  {
    Header: (props) => <TemplateSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <TemplateSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => (
      <TemplateCustomHeader tableProps={props} title='Nome' className='min-w-125px' />
    ),
    accessor: 'nome',
  },
  {
    Header: (props) => (
      <TemplateCustomHeader tableProps={props} title='QTD CNPJs' className='min-w-55px text-center' />
    ),
    accessor: 'qtd_cnpjs',
    Cell: (props) => <div className='text-center'>{props.data[props.row.index].qtd_cnpjs}</div>
  },
  {
    Header: (props) => (
      <TemplateCustomHeader tableProps={props} title='Criado em' className='min-w-85px' />
    ),
    accessor: 'criado_em',
    // Cell: ({...props}) => <DatetimeCell {...props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <TemplateCustomHeader tableProps={props} title='Criado Por' className='min-w-125px' />
    ),
    accessor: 'criado_por',
  },
  {
    Header: (props) => (
      <TemplateCustomHeader tableProps={props} title='Campos Personalizados' className='min-w-55px' />
    ),
    accessor: 'campos_customizados',
    Cell: ({...props}) => <TemplateCamposCustomizadosCell {...props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <TemplateCustomHeader tableProps={props} title='Download' className='min-w-55px' />
    ),
    accessor: 'url_documento',
    Cell: ({...props}) => <TemplateDownloadCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => (
      <TemplateCustomHeader tableProps={props} title='Ações' className='text-end min-w-80px' />
    ),
    id: 'acoes',
    Cell: ({...props}) => <TemplateActionsCell id={props.data[props.row.index].id} />,
  },
]

export {templateesColumns}
