import {createRoot} from 'react-dom/client'
// Axios
import axios from 'axios'
import {Chart, registerables} from 'chart.js'
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'

// Apps
import {MetronicI18nProvider} from './_metronic/i18n/Metronici18n'
/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.react.scss'
import {AppRoutes} from './app/routing/AppRoutes'
import {AuthProvider, setupAxios} from './app/modules/auth'
import {AuthGoogleProvider} from './app/context/authGoogle'
import {SnackbarProvider} from 'notistack'
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
setupAxios(axios)
Chart.register(...registerables)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retryDelay: 3000,
      refetchOnWindowFocus: true,
      refetchOnMount: false,
      refetchOnReconnect: true,
      retry: false,
      staleTime: 1000 * 60 * 60 * 1, // 1h
      cacheTime: 1 * 1000, // 1s
    },
  },
})
const container = document.getElementById('root')
if (container) {
  createRoot(container).render(
    <SnackbarProvider
      autoHideDuration={5000}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom',
      }}
      preventDuplicate
    >
      <QueryClientProvider client={queryClient}>
        {process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} />}
        <MetronicI18nProvider>
          <AuthGoogleProvider>
            <AuthProvider>
              <AppRoutes />
            </AuthProvider>
          </AuthGoogleProvider>
        </MetronicI18nProvider>
      </QueryClientProvider>
    </SnackbarProvider>
  )
}
