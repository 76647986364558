import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {ColaboradoresListHeader} from './components/header/ColaboradoresListHeader'
import {ColaboradoresTable} from './table/ColaboradoresTable'
import {ColaboradorEditModal} from './cloaborador-edit-modal/ColaboradorEditModal'
import {KTCard} from '../../../_metronic/helpers'

const ColaboradoresList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <ColaboradoresListHeader />
        <ColaboradoresTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <ColaboradorEditModal />}
    </>
  )
}

const ColaboradoresListWrapper = () => (
  <QueryResponseProvider>
    <ListViewProvider>
      <ColaboradoresList />
    </ListViewProvider>
  </QueryResponseProvider>
)

export {ColaboradoresListWrapper}
