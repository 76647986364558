import {useQuery} from '@tanstack/react-query'
import {TemplateEditModalForm} from './TemplateEditModalForm'
import {isNotEmpty, QUERIES} from '../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import {buscarTemplatePorId} from '../core/_requests'
import { useAuth } from '../../auth'
import { buscarTodosPerfis } from '../../perfil/core/_requests'

const TemplateEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {currentUser} = useAuth()
  const id_organizacao = currentUser!.id_organizacao;

  const templateQuery = useQuery(
    [`${QUERIES.TEMPLATES_LIST}-template-${itemIdForUpdate}`],
    () => {
      return buscarTemplatePorId(itemIdForUpdate)  
    },
    {
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  const perfisQuery = useQuery(
    [`${QUERIES.PERFIL_LIST}-template`],
    () => {
      return buscarTodosPerfis({size: 1000})  // TODO: usar o async do react-select
    },
    {
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  const isLoading = templateQuery.isLoading && perfisQuery.isLoading
  const error = templateQuery.error && perfisQuery.error

  const template = templateQuery.data
  const perfis = perfisQuery.data?.data.itens

  if (!itemIdForUpdate) {
    return (
      <TemplateEditModalForm isTemplateLoading={false} perfis={perfis} template={{id: undefined, id_organizacao}} />
    )
  }

  if (!isLoading && !error && template) {
    return <TemplateEditModalForm isTemplateLoading={isLoading} perfis={perfis} template={{...template, id_organizacao}} />
  }

  return null
}

export {TemplateEditModalFormWrapper}
