import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {ListHeader} from './components/header/ListHeader'
import {Table} from './table/Table'
import {EditModal} from './edit-modal/EditModal'
import {KTCard} from '../../../_metronic/helpers'

const List = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <ListHeader />
        <Table />
      </KTCard>
      {itemIdForUpdate !== undefined && <EditModal />}
    </>
  )
}

const DocumentosListWrapper = () => (
  <QueryResponseProvider>
    <ListViewProvider>
      <List />
    </ListViewProvider>
  </QueryResponseProvider>
)

export {DocumentosListWrapper}
