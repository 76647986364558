import {FC, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty} from '../../../../_metronic/helpers'
import {initialColaborador, Colaborador, PerfilColaborador, Status} from '../core/_models'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {novoColaborador, atualizarColaborador} from '../core/_requests'
import {useQueryResponse} from '../../../components/pageable/query-response.provider'
import {enqueueSnackbar} from 'notistack'
import {ListLoading} from '../../../components/ListLoading'

type Props = {
  isColaboradorLoading: boolean
  colaborador: Colaborador
  perfis?: PerfilColaborador[]
}

const ColaboradorEditModalForm: FC<Props> = ({colaborador, perfis, isColaboradorLoading}) => {
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [error, setError] = useState<string | undefined>()
  const editUserSchema = Yup.object().shape({
    email: Yup.string()
      .email('Formato inválido de email')
      .min(3, 'Mínimo de 3 caracteres')
      .max(50, 'Máximo de 50 caracteres')
      .required('Email é obrigatório'),
    nome: Yup.string()
      .min(3, 'Mínimo de 3 caracteres')
      .max(50, 'Máximo de 50 caracteres')
      .required('Nome é obrigatório'),
    sobrenome: Yup.string()
      .min(3, 'Mínimo de 3 caracteres')
      .max(50, 'Máximo de 50 caracteres')
      .required('Sobrenome é obrigatório'),
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: {
      ...colaborador,
      id_perfil: colaborador.id_perfil || colaborador.perfil?.id,
      nome: colaborador.nome || initialColaborador.nome,
      sobrenome: colaborador.sobrenome || initialColaborador.sobrenome,
      email: colaborador.email || initialColaborador.email,
      id_organizacao: colaborador.id_organizacao || initialColaborador.id_organizacao,
      status: colaborador.status || Status.find((s) => s.selected)?.id || -1,
    },
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting, setFieldError}) => {
      if (!values.id_perfil) {
        setFieldError('id_perfil', 'Escolha um perfil')
      }
      setSubmitting(true)
      setError(undefined)
      try {
        const data = values
        delete data.perfil
        if (isNotEmpty(values.id)) {
          await atualizarColaborador(data)
          enqueueSnackbar('Colaborador atualizado com sucesso', {variant: 'info'})
        } else {
          await novoColaborador(data)
          enqueueSnackbar('Colaborador salvo com sucesso', {variant: 'info'})
        }
        cancel(true)
      } catch (ex) {
        setError((ex as any)?.response?.data?.message || (ex as string))
        console.error(ex)
      } finally {
        setSubmitting(false)
      }
    },
  })

  return (
    <>
      <form
        id='kt_modal_add_colaborador_form'
        className='form'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y'
          id='kt_modal_add_colaborador_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_colaborador_header'
          data-kt-scroll-wrappers='#kt_modal_add_colaborador_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Nome</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Nome'
              {...formik.getFieldProps('nome')}
              type='text'
              name='nome'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.nome && formik.errors.nome},
                {
                  'is-valid': formik.touched.nome && !formik.errors.nome,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isColaboradorLoading}
            />
            {formik.touched.nome && formik.errors.nome && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.nome}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Sobrenome</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Sobrenome'
              {...formik.getFieldProps('sobrenome')}
              type='text'
              name='sobrenome'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.sobrenome && formik.errors.sobrenome},
                {
                  'is-valid': formik.touched.sobrenome && !formik.errors.sobrenome,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isColaboradorLoading}
            />
            {formik.touched.sobrenome && formik.errors.sobrenome && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.sobrenome}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Email</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Email'
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.email && formik.errors.email},
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
              type='email'
              name='email'
              autoComplete='off'
              disabled={formik.isSubmitting || isColaboradorLoading}
            />
            {/* end::Input */}
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-5'>Perfil</label>
            {/* end::Label */}
            {/* begin::Roles */}
            {/* begin::Input row */}
            <div className='d-flex fv-row'>
              {/* begin::Radio */}
              <select
                className='form-select'
                aria-label='Select example'
                {...formik.getFieldProps('id_perfil')}
                name='id_perfil'
                onChange={(evt) => {
                  formik.setFieldValue(
                    'id_perfil',
                    perfis?.find((p) => p.id.toString() === evt.target.value)?.id
                  )
                }}
                onBlur={(evt) => {
                  formik.setFieldValue(
                    'id_perfil',
                    perfis?.find((p) => p.id.toString() === evt.target.value)?.id
                  )
                }}
              >
                <option disabled selected>
                  {' '}
                  Selecione um perfil{' '}
                </option>
                {perfis?.map((p) => (
                  <option key={p.id} value={p.id}>
                    {p.nome}
                  </option>
                ))}
              </select>
            </div>
            {formik.touched.id_perfil && formik.errors.id_perfil && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.id_perfil}</span>
                </div>
              </div>
            )}
            {/* end::Input row */}
            {/* begin::Input row */}
            {/* end::Input row */}
            {/* end::Roles */}
          </div>

          <div className='mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-5'>Status</label>
            {/* end::Label */}
            {/* begin::Roles */}
            {/* begin::Input row */}
            <div className='d-flex fv-row'>
              {/* begin::Radio */}
              <select
                className='form-select'
                aria-label='Select example'
                {...formik.getFieldProps('status')}
                name='status'
                value={formik.values.status}
                onChange={(evt) => {
                  formik.setFieldValue(
                    'status',
                    Status?.find((p) => p.id.toString() === evt.target.value)?.id
                  )
                }}
                onBlur={(evt) => {
                  formik.setFieldValue(
                    'status',
                    Status?.find((p) => p.id.toString() === evt.target.value)?.id
                  )
                }}
              >
                <option disabled value='-1'> Selecione um status </option>
                {Status?.map((p) => (
                  <option key={p.id} value={p.id}>
                    {p.descricao}
                  </option>
                ))}
              </select>
            </div>
            {/* end::Input row */}
            <div className='separator separator-dashed my-5'></div>
            {/* begin::Input row */}
            {/* end::Input row */}
            {/* end::Roles */}
          </div>
          {/* end::Input group */}
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-5'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-colaboradores-modal-action='cancel'
            disabled={formik.isSubmitting || isColaboradorLoading}
          >
            Cancelar
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-colaboradores-modal-action='submit'
            disabled={
              isColaboradorLoading || formik.isSubmitting || !formik.isValid || !formik.touched
            }
          >
            <span className='indicator-label'>Salvar</span>
            {(formik.isSubmitting || isColaboradorLoading) && (
              <span className='indicator-progress'>
                Por favor aguarde...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          {error && (
            <div className='alert alert-danger d-flex align-items-center p-5 m-5'>
              Houve algum erro, {error}
            </div>
          )}
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isColaboradorLoading) && <ListLoading />}
    </>
  )
}

export {ColaboradorEditModalForm}
