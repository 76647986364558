/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {useAuth} from '../core/Auth'
import {GoogleLogin} from './GoogleLogin'
import {signInWithEmailAndPassword} from 'firebase/auth'
import {firebaseAppAuthentication} from '../../../services/firebaseConfig'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Formato de email inválido')
    .min(3, 'Mínimo de 3 caracteres')
    .max(50, 'Máximo de 50 caracteres')
    .required('Email é obrigatório'),
  password: Yup.string()
    .min(3, 'Mínimo de 3 caracteres')
    .max(50, 'Máximo de 50 caracteres')
    .required('Senha é obrigatória'),
})

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function handleErrorMessage(error: any) {
  if (error?.code) {
    if(error?.message.includes('auth/invalid-login-credentials')) {
      return 'Usuário não cadastrado, por favor relize o cadastro'
    } else if (error?.code.includes('auth/email-already-in-use')) {
      return 'Usuário já existe, por favor faça login ou recupere sua senha'
    } else if (error?.code.includes('auth/wrong-password')) {
      return 'Senha incorreta, tente recuperar sua senha'
    } else if (error?.code.includes('auth/too-many-requests')) {
      return 'Houve uma instabilidade, tente daqui alguns momentos'
    } else {
      // Add a default case for unknown error codes
      return 'Ocorreu um erro durante o login. Tente novamente.'
    }
  }
  return 'Dados incorretos, ' + (error?.response?.data?.message || error?.message || 'Erro desconhecido')
}


export function Login() {
  const [loading, setLoading] = useState(false)
  const {saveAuth} = useAuth()
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        // const {data} = await login(values.email, values.password)
        const response = await signInWithEmailAndPassword(
          firebaseAppAuthentication,
          values.email,
          values.password
        )
        // TODO: Alterar para quando usar outra solução como firebase
        const firebaseToken = await response.user.getIdToken(true)

        saveAuth({
          api_token: firebaseToken,
        })
        navigate('/auth/do-login')
      } catch (error: any) {
        console.error(error)
        saveAuth(undefined)
        setStatus(handleErrorMessage(error))
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      <GoogleLogin />
      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <div className='separator separator-content my-14'>
          <span className='text-gray-500 fw-semibold fs-7'>
            Ou o email e a senha para continuar
          </span>
        </div>
        // <div className='mb-10 bg-light-info p-8 rounded'>
        //   <div className='text-info'>
        //     Use o email {/* <strong>admin@demo.com</strong>  */} e a senha{' '}
        //     {/* <strong>demo</strong>  */} para continuar.
        //   </div>
        // </div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Senha</label>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />

        {/* begin::Link */}
        <Link to='/auth/forgot-password' className='link-primary'>
          Esqueceu a senha ?
        </Link>
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continuar</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Por favor aguarde...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

      <div className='text-gray-500 text-center fw-semibold fs-6'>
        Não é um membro ainda?{' '}
        <Link to='/auth/registration' className='link-primary'>
          Cadastrar
        </Link>
      </div>
    </form>
  )
}
