/* eslint-disable react-hooks/exhaustive-deps */
import {FC} from 'react'
import {QUERIES, WithChildren} from '../../../../_metronic/helpers'
import {buscarTodosPerfis} from './_requests'
import {useAuth} from '../../auth'
import {PageableQueryResponseProvider} from '../../../components/pageable/query-response.provider'

const QueryResponseProvider: FC<WithChildren> = ({children}) => {
  const auth = useAuth()

  const id_organizacao = auth.currentUser!.id_organizacao
  const query = `${QUERIES.PERFIL_LIST}-todos-${id_organizacao}`

  return (
    <PageableQueryResponseProvider query={query} queryFunction={buscarTodosPerfis}>
      {children}
    </PageableQueryResponseProvider>
  )
}
export {QueryResponseProvider}
