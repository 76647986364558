import { Outlet, Route, Routes } from 'react-router-dom'
import { PageTitle } from '../../_metronic/layout/core'
import { ColaboradoresListWrapper } from '../modules/colaboradores/ColaboradoresList'

const Colaboradores = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/'
          element={
            <>
              <PageTitle>Usuários</PageTitle>
              <ColaboradoresListWrapper />
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export default Colaboradores
