/* eslint-disable react-hooks/exhaustive-deps */
import {createContext, useContext, useState} from 'react'
import {useQuery} from '@tanstack/react-query'
import {Pageable} from './pageable.interface'
import {
  PaginationState,
  Response,
  WithChildren,
  initialQueryState,
} from '../../../_metronic/helpers'

export interface QueryResponseType<T extends Pageable = {}> {
  query: string
  queryFunction(pagination: Pageable<T>): Promise<Response<T>>
}

export type QueryResponseContextProps<T> = {
  response?: Response<Pageable<T>> | undefined
  refetch: () => void
  isLoading: boolean
  query: string
  state: Pageable
  nextPage: VoidFunction
  previousPage: VoidFunction
  updateState(p: Pageable): void
}

const QueryResponseContext = createContext<QueryResponseContextProps<Pageable>>({
  refetch: () => {},
  isLoading: false,
  query: '',
  nextPage: () => {},
  previousPage: () => {},
  updateState: () => {},
  state: {} as Pageable,
})

function PageableQueryResponseProvider({
  children,
  query,
  queryFunction,
}: QueryResponseType & WithChildren) {
  const [page, setPage] = useState<Pageable>({
    size: 10,
    page: 0,
    search: ''
  } as Pageable)

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    [query, ...Object.values(page)],
    async () => {
      const response = await queryFunction(page)
      return {
        ...response,
        data: {
          ...response.data,
          ...page
        },
      }
    },
  )

  return (
    <QueryResponseContext.Provider
      value={{
        isLoading: isFetching,
        refetch,
        response,
        query,
        nextPage: () => {
          setPage((prev) => ({...prev, page: (prev.page ?? 0) + 1}))
        },
        previousPage: () => {
          setPage((prev) => {
            const curr = (prev.page ?? 0) - 1
            return {...prev, page: curr < 0 ? 0 : curr}
          })
        },
        updateState: (pageable: Pageable) => {
          setPage((prev) => ({...prev, ...pageable}))
          window.scrollTo({
            behavior: 'smooth',
            top: 0,
          })
        },
        state: page,
      }}
    >
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

function useQueryResponseData<T>() {
  const {response} = useQueryResponse()
  if (!response) {
    return []
  }

  return (response?.data?.itens as T[]) ?? []
}

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const {response} = useQueryResponse()

  const totalItems = response?.data?.totalItens ?? 0
  const size = response?.data?.size ?? 0
  const currentPage = (response?.data?.page ?? 0) + 1
  const pages = Math.ceil(totalItems / (size ?? 1))

  for (let i = 0; i < pages; i++) {
    const genPage = i + 1
    defaultPaginationState.links?.push({
      active: currentPage === genPage,
      label: `${genPage}`,
      page: genPage,
      url: `?page=${genPage}`,
    })
  }

  defaultPaginationState.page = currentPage
  defaultPaginationState.size = size ?? 10

  if (!response || !response.payload || !response.payload.pagination) {
    return defaultPaginationState
  }

  return response.payload.pagination
}

const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

export {
  PageableQueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
}
