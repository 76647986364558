import {ID, Response} from '../../../../_metronic/helpers'
import { Permissao } from '../../perfil/core/_models'

export type StatusType = {
  id: number
  descricao: string
  selected?: boolean
}

export const Status: StatusType[] = [
  {id: 0, descricao: 'Inativo'},
  {id: 1, descricao: 'Ativo', selected: true},
  {id: 2, descricao: 'Bloqueado'},
]

export type Colaborador = {
  id?: number
  id_organizacao?: ID
  nome?: string
  sobrenome?: string
  email?: string
  senha?: string
  idFirebase?: string
  perfil?: PerfilColaborador
  status?: number
  id_perfil?: number
  permissoes?: Permissao[]
}

export type PerfilColaborador = {
  id: number
  nome: string
  master: boolean
  permissoes?: Permissao[]
}

export type ColaboradoresQueryResponse = Response<Colaborador[]>

export const initialColaborador: Colaborador = {
  id: 0,
  id_organizacao: 0,
  nome: '',
  sobrenome: '',
  email: '',
  status: 1,
}
