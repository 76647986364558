import {FC} from 'react'

type Props = {
  status?: number
}

const ColaboradorStatusCell: FC<Props> = ({status}) => (
  <>
    {status === 0 && <div className='badge badge-light-secondary fw-bolder'>Desativado</div>}
    {status === 1 && <div className='badge badge-light-success fw-bolder'>Ativo</div>}
    {status === 2 && <div className='badge badge-light-dark fw-bolder'>Bloqueado</div>}
  </>
)

export {ColaboradorStatusCell}
