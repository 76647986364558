import axios from 'axios'
import {ID} from '../../../../_metronic/helpers'
import {Perfil, Permissao} from './_models'
import { Pageable } from '../../../components/pageable/pageable.interface'

const API_URL = process.env.REACT_APP_API_BACK_TODOC
const PERFIL_URL = `${API_URL}/perfis`
const PERMISSAO_URL = `${API_URL}/permissoes`

function buscarPerfil(id: ID) {
  return axios
    .get<Perfil>(`${PERFIL_URL}`, {
      params: {
        id_perfil: id,
      },
    })
    .then((d) => d.data)
}

function buscarPerfilPorNome(nome: string) {
  return axios.get<Perfil>(`${PERFIL_URL}/contem/${nome}`).then((d) => d.data)
}

function buscarPerfilComPermissoes(id_organizacao: number) {
  return axios
    .get<Perfil>(`${PERFIL_URL}/permissoes`, {
      params: {
        id_organizacao,
      },
    })
    .then((d) => d.data)
}

function buscarTodosPerfis(params?: Pageable) {
  return axios.get<Pageable<Perfil>>(`${PERFIL_URL}/todos`, {params})
}

function buscarTodasPermissoes() {
  return axios.get<Permissao[]>(`${PERMISSAO_URL}/todos`).then(d => d.data)
}

const deletarPerfil = (id: ID): Promise<any> => {
  return deletarVariosPerfis([id])
}

function tooglePerfil(id: ID) {
  return axios.post(`${PERFIL_URL}/toggle`, {}, {
    params: {
      id_perfil: id,
    },
  })
}

function deletarVariosPerfis(ids: ID[]) {
  // const requests = ids.map((id) =>
  //   axios.delete(`${PERFIL_URL}`, {
  //     params: {
  //       id_perfil: id,
  //     },
  //   })
  // )
  // // TODO alterar para chamar como /varios/id,id,id
  // return axios.all(requests)
  return axios.delete(`${PERFIL_URL}`, {
    data: ids,
  })
}

function atualizarPerfil(perfil: Perfil) {
  return axios.post(`${PERFIL_URL}`, perfil)
}

function criarPerfil(perfil: Perfil) {
  return axios.put(`${PERFIL_URL}`, perfil)
}

export {
  atualizarPerfil,
  buscarPerfil,
  buscarPerfilComPermissoes,
  buscarPerfilPorNome,
  buscarTodosPerfis,
  criarPerfil,
  deletarPerfil,
  deletarVariosPerfis,
  buscarTodasPermissoes,
  tooglePerfil
}
