import {createContext, useContext} from 'react'
import {Response, WithChildren} from '../../../../../_metronic/helpers'
//import {IDetalhesConta} from './SettingsModel'
import {UserModel, useAuth} from '../../../auth'
import {useQuery} from '@tanstack/react-query'
import axios from 'axios'

export type ContaContextProps = {
  detalhesConta?: UserModel
  refetch: VoidFunction
  atualizarConta(userModel: Partial<UserModel>): Promise<Response<UserModel>>
}

const API_URL = process.env.REACT_APP_API_BACK_TODOC
const USUARIO_URL = `${API_URL}/usuarios`

const ContaContext = createContext<ContaContextProps>({} as ContaContextProps)

export function ContaProvider({children}: WithChildren) {
  const auth = useAuth()

  const {isFetching, refetch, data} = useQuery<UserModel>(
    ['LoadAccountData'],
    async () => {
      const response = await axios.get<UserModel>(USUARIO_URL + '/' + auth.currentUser?.id!)
      return response.data
    },
  )

  async function atualizarConta(userModel: Partial<UserModel>) {
    const post = await axios.post(USUARIO_URL + '/pessoais', userModel)
    auth.setCurrentUser(post.data);
    refetch()
    return post
  }

  return (
    <ContaContext.Provider
      value={{
        detalhesConta: data,
        refetch,
        atualizarConta,
      }}
    >
      {isFetching && <>Carregando...</>}
      {!isFetching && children}
    </ContaContext.Provider>
  )
}

export const useContaProvider = () => useContext(ContaContext)
