import {Outlet, Route, Routes} from 'react-router-dom'
import {PageTitle} from '../../_metronic/layout/core'
import {PerfilListWrapper} from '../modules/perfil/List'

const Perfil = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/'
          element={
            <>
              <PageTitle>Perfis</PageTitle>
              <PerfilListWrapper />
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export default Perfil
