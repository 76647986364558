import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import Colaboradores from '../pages/colaboradores'
import Templates from '../pages/templates'
import {PrincipalWrapper} from '../pages/Principal'
import { DocumentosWrapper } from '../pages/documentos'
import PermissoesPage from '../pages/perfil'
import { Conta } from '../modules/accounts/components/settings/Conta'
import UsersPage from '../modules/apps/user-management/UsersPage'

const PrivateRoutes = () => {
  
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/principal' />} />
        <Route path='users/*' element={<UsersPage />} />
        {/* Pages */}
        <Route path='colaboradores/*' element={<Colaboradores />} />
        <Route path='perfil/*' element={<PermissoesPage />} />
        <Route path='templates/*' element={<Templates />} />
        <Route path='documentos/*' element={<DocumentosWrapper />} />
        <Route path='principal/*' element={<PrincipalWrapper />} />
        <Route path='conta/*' element={<Conta />} />
        {/* Lazy Modules */}
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

export {PrivateRoutes}
