import {AuthModel} from './_models'
import {getAuth as getFirebaseAuth} from 'firebase/auth'

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export function setupAxios(axios: any) {
  // Set default headers
  axios.defaults.headers.Accept = 'application/json'
  // axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
  // Add CORS headers
  // axios.defaults.headers['Access-Control-Allow-Methods'] = 'GET, POST, PUT, DELETE, OPTIONS'
  // axios.defaults.headers['Access-Control-Allow-Headers'] = 'Content-Type, Authorization'
  
  axios.interceptors.response.use(undefined, async (err: any) => {
    const {config, message} = err
    config.retry -= 1
    
    if (!config?.retry && config?.retry < 0) {
      return Promise.reject(err)
    }

    if (401 === err.response.status && 
      !err.request.responseURL.includes('/firebase/auth')) {
      
      const auth = getFirebaseAuth()
      if (auth.currentUser) {
        try {
          const firebaseToken = await auth.currentUser.getIdToken(true);
          if (firebaseToken) {
            setAuth({
              api_token: firebaseToken,
            })
            return axios(config)
          } else {
            return Promise.reject(err)
          }
        } catch {
          return Promise.reject(err)
        }
      } else {
        return document.location = '/'
      }
    } 

    if (!(message.includes('timeout') || message.includes('Network Error'))) {
      return Promise.reject(err)
    }    
    
    const delayRetryRequest = new Promise<void>((resolve) => {
      setTimeout(() => {
        console.log('retry the request', config.url)
        resolve()
      }, config.retryDelay || 3000)
    })
    return delayRetryRequest.then(() => axios(config))
  })

  axios.interceptors.request.use(
    (config: {retry: number; retryDelay: number; headers: {Authorization: string}}) => {
      const auth = getAuth()

      if (auth && auth.api_token) {
        config.headers.Authorization = `Bearer ${auth.api_token}`
      }

      if (!config.retry && isNaN(config.retry)) {
        config.retry = 3
      }  

      return config
    }
  )
}

export {getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY}
