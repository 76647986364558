import {FC} from 'react'
import { downloadTemplate } from '../../core/_requests'
import { ID } from '../../../../../_metronic/helpers'

type Props = {
  id?: ID
}

const TemplateDownloadCell: FC<Props> = ({id}) => {
  return (
    <>
        <button
          onClick={(e) => {
            e.preventDefault()
            downloadTemplate(id)
          }}
          className='btn btn-icon btn-primary'
          title='Download'
        >
          <i className='las la-download fs-2'></i>
        </button>
    </>
  )
}

export {TemplateDownloadCell}
