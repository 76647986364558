/* eslint-disable jsx-a11y/anchor-is-valid */
import {useContext} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {AuthGoogleContext} from '../../../context/authGoogle'
import {useAuth} from '../core/Auth'
import { useNavigate } from 'react-router-dom'

export function GoogleLogin() {
  const googleAuth = useContext(AuthGoogleContext)
  const {saveAuth} = useAuth()
  const navigate = useNavigate()
  async function doLogin() {
    const response = await googleAuth.signInGoogle();
    const token = await response.user.getIdToken(true);
    saveAuth({
        api_token: token
    });
    navigate('/auth/do-login')
  }

  return (
    <a
      href='#'
      className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100 my-2'
      onClick={doLogin}
    >
      <KTSVG path='/media/svg/brand-logos/google-icon.svg' className='h-15px me-3' />
      Entre com Google
    </a>
  )
}
