import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {WithChildren} from '../../../../_metronic/helpers'
import {AuthGoogleContext} from '../../../context/authGoogle'
import {onAuthStateChanged} from 'firebase/auth'
import {firebaseAppAuthentication} from '../../../services/firebaseConfig'
import {useNavigate} from 'react-router-dom'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
  hasPermission(ids: number[], tipo_permissao?: string): Boolean
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
  hasPermission: () => false,
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const googleAuth = useContext(AuthGoogleContext)

  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = async () => {
    await googleAuth?.signOut()
    saveAuth(undefined)
    setCurrentUser(undefined)
  }

  function hasPermission(ids: number[], tipo_permissao: string = 'SISTEMA'): boolean {
    return !![...(currentUser?.permissoes ?? []), ...(currentUser?.permissoesArquivos ?? [])]?.find(
      (perm) => ids.includes(perm.id) && perm.tipo_permissao === tipo_permissao.toUpperCase()
    )
  }

  return (
    <AuthContext.Provider
      value={{auth, saveAuth, currentUser, setCurrentUser, logout, hasPermission}}
    >
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout} = useAuth()
  const navigate = useNavigate()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        if (!didRequest.current) {
          navigate('/auth/do-login')
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          await logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    onAuthStateChanged(firebaseAppAuthentication, async (user) => {
      const firebaseToken = await user?.getIdToken(true)

      const hasToken = (auth && auth.api_token) || firebaseToken
      if (hasToken) {
        requestUser(hasToken)
      } else {
        await logout()
        setShowSplashScreen(false)
      }
    })

    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
