import {useEffect} from 'react'
import {useAuth} from './core/Auth'
import { useNavigate } from 'react-router-dom';

export function Logout() {
  const {logout} = useAuth()
  const navigate = useNavigate();

  useEffect(() => {
    async function call(){
      await logout()
      navigate('/auth/login');
    }
    call()
  }, [logout, navigate])

  return (
    <></>
  )
}
