import axios from 'axios'
import {ID, Response} from '../../../../_metronic/helpers'
import {TemplateFormType} from '../template-edit-modal/TemplateEditModalForm'
import {Template, TemplatesQueryResponse} from './_models'
import { Pageable } from '../../../components/pageable/pageable.interface'
import { enqueueSnackbar } from 'notistack'

const API_URL = process.env.REACT_APP_API_BACK_TODOC
const TEMPLATE_URL = `${API_URL}/templates`

const buscarTemplates = (params?: Pageable): Promise<TemplatesQueryResponse> => {
  return axios.get(`${TEMPLATE_URL}/todos`, { params })
}

const buscarTemplatePorId = (id: ID): Promise<Template | undefined> => {
  return axios.get(`${TEMPLATE_URL}/${id}`).then((response: Response<Template>) => response.data)
}

function uploadTemplate(templateForm: Partial<TemplateFormType>) {
  const fd = new FormData();
  fd.append('template', new Blob([JSON.stringify(templateForm.template)], { type: 'application/json' }));
  templateForm.file?.forEach(f => {
    if(f) {
      fd.append('file', f);
      fd.append('filename', f.name);
      fd.append('mimetype', f.type);
    }
  })
  return axios.post(`${TEMPLATE_URL}/upload`, fd, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Accept': 'application/json',
    },
  })
}

const novoTemplate = (template: Template): Promise<Template | undefined> => {
  return axios.put(TEMPLATE_URL, template).then((response: Response<Template>) => response.data)
}

const atualizarTemplate = (template: Template): Promise<Template | undefined> => {
  return axios.post(TEMPLATE_URL, template).then((response: Response<Template>) => response.data)
}

const deletarTemplate = (templateId: ID): Promise<any> => {
  return deletarTemplatesSelecionados([templateId])
}

const deletarTemplatesSelecionados = (templateIds: Array<ID>): Promise<any> => {
  return axios.delete(`${TEMPLATE_URL}`, {
    data: templateIds,
  })
}

async function downloadTemplate(templateId: ID): Promise<void> {
  try {
    const response = await axios.get(`${TEMPLATE_URL}/download`, {
      params: { template_id: templateId }
    });

     // Create an invisible anchor element
     const a = document.createElement('a');
     a.style.display = 'none';
     a.href = response.data.link;
     // If you want to force download instead of opening in browser
     if (response.data.file_name) {
       a.download = response.data.file_name;
     }
     
     document.body.appendChild(a);
     a.click();
     document.body.removeChild(a);

    enqueueSnackbar('Download realizado com sucesso!', {variant: 'info'})
  } catch (error) {
    handleDownloadError(error);
  }
}

function handleDownloadError(error: any): void {
  console.error('Download error:', error);
  enqueueSnackbar('Falha no Download.', {variant: 'error'})
  // throw error; // Optional: rethrow the error if needed for further handling
}

export {
  atualizarTemplate,
  buscarTemplatePorId,
  buscarTemplates,
  deletarTemplate,
  deletarTemplatesSelecionados,
  novoTemplate,
  uploadTemplate,
  downloadTemplate,
}
