// @ts-nocheck
import {Column} from 'react-table'
import {StatusCell} from './StatusCell'
import {ActionsCell} from './ActionsCell'
import {SelectionCell} from './SelectionCell'
import {CustomHeader} from './CustomHeader'
import {SelectionHeader} from './SelectionHeader'
import {Colaborador} from '../../core/_models'
// import {TextCell} from './TextCell'

// const fotmatter = new Intl.DateTimeFormat('pt-BR');

export const tableColumns: ReadonlyArray<Column<Colaborador>> = [
  {
    Header: (props) => <SelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: (props) => <SelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Nome' className='min-w-125px' />,
    accessor: 'nome',
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Descrição' className='min-w-125px' />
    ),
    accessor: 'descricao',
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Ativo' className='min-w-125px' />,
    id: 'ativo',
    Cell: (props) => <StatusCell status={props.data[props.row.index].ativo} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Ações' className='text-end min-w-100px' />
    ),
    id: 'acoes',
    Cell: (props) => <ActionsCell item={props.data[props.row.index]} />,
  },
]
