import {useQuery} from '@tanstack/react-query'
import {ColaboradorEditModalForm} from './ColaboradorEditModalForm'
import {isNotEmpty, QUERIES} from '../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import {buscarColaboradorPorId} from '../core/_requests'
import {useAuth} from '../../auth'
import {buscarTodosPerfis} from '../core/_requests'
import {PerfilColaborador} from '../core/_models'

const ColaboradorEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {currentUser} = useAuth()
  const id_organizacao = currentUser!.id_organizacao

  const colaboradorQuery = useQuery(
    [`${QUERIES.COLABORADORES_LIST}-colaborador-${itemIdForUpdate}`],
    () => {
      return buscarColaboradorPorId(itemIdForUpdate)
    },
    {
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  const perfilQuery = useQuery(
    [`${QUERIES.PERFIL_LIST}-${id_organizacao}`],
    () => {
      return buscarTodosPerfis()
    },
    {
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  const isLoading = colaboradorQuery.isLoading && perfilQuery.isLoading // && permissoesQuery.isLoading
  const error = colaboradorQuery.error && perfilQuery.error // && permissoesQuery.error

  const colaborador = colaboradorQuery.data
  const perfis = perfilQuery.data?.itens

  if (!itemIdForUpdate) {
    return (
      <ColaboradorEditModalForm
        isColaboradorLoading={isLoading}
        colaborador={{id: undefined, id_organizacao}}
        perfis={perfis as PerfilColaborador[]}
      />
    )
  }

  if (!isLoading && !error && colaborador) {
    return (
      <ColaboradorEditModalForm
        isColaboradorLoading={isLoading}
        colaborador={{
          ...colaborador,
          id_organizacao,
          id_perfil: colaborador.id_perfil || colaborador.perfil?.id,
        }}
        perfis={perfis as PerfilColaborador[]}
      />
    )
  }

  return null
}

export {ColaboradorEditModalFormWrapper}
