import {ID, Response} from '../../../../_metronic/helpers'
import { Pageable } from '../../../components/pageable/pageable.interface';

export type StatusType = {
  id: number;
  descricao: string;
  selected?: boolean;
}

export const Status: StatusType[] = [
  {id: 0,descricao: 'Inativo'},
  {id: 1,descricao: 'Ativo', selected: true},
  {id: 2,descricao: 'Bloqueado'},
]

export type CamposCustomizadosType = {
  chave: string
  descricao?: string
}

export type Template = {
  id?: ID
  id_organizacao?: number
  criado_por?: string;
  nome: string
  extensao?: string
  qtd_cnpjs: number
  url_dopcumento?: string
  criado_em?: number | Date;
  id_perfis: number[]
  campos_customizados?: CamposCustomizadosType[]
}

export type PerfilTemplate = {
  id:number,
  nome:string,
  master: boolean
}


export type TemplatesQueryResponse = Response<Pageable<Template>>

export const initialTemplate: Template = {
  id: 0,
  id_organizacao: 0,
  criado_por: '',
  nome: '',
  qtd_cnpjs: 1,
  url_dopcumento: 'url',
  criado_em: new Date(),
  id_perfis: [],
  campos_customizados: [] as CamposCustomizadosType[]
}
