// @ts-nocheck
import {Column} from 'react-table'
import {SelectionCell} from './SelectionCell'
import {CustomHeader} from './CustomHeader'
import {SelectionHeader} from './SelectionHeader'
import {Documentos} from '../../core/_models'
import { ExecucaoDownloadCell } from './ExecucaoDownloadCell'
import { ExecucaoCustomHeader } from './ExecucaoCustomHeader'
import { ActionsCell } from './ActionsCell'
import { CnpjsCell } from './CnpjsCell'

export const tableColumns: ReadonlyArray<Column<Documentos>> = [
  {
    Header: (props) => <SelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: (props) => <SelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Criado Por' className='min-w-125px' />
    ),
    accessor: 'criador',
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Data Criação' className='min-w-125px' />
    ),
    accessor: 'criado_em',
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Descrição' className='min-w-125px' />,
    accessor: 'descricao',
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Modelo' className='min-w-125px' />
    ),
    accessor: 'template',
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Cnpjs' className='min-w-125px' />
    ),
    accessor: 'cnpjs_in',
    Cell: ({...props}) => <CnpjsCell {...props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Expira em' className='min-w-125px' />
    ),
    accessor: 'expira_em',
    // Cell: ({...props}) => <DatetimeCell {...props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <ExecucaoCustomHeader tableProps={props} title='Download' className='min-w-55px' />
    ),
    accessor: 'url_documento',
    Cell: ({...props}) => <ExecucaoDownloadCell arquivo_doc={props.data[props.row.index].arquivo_doc} 
    arquivo_pdf={props.data[props.row.index].arquivo_pdf} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Ações' className='text-end min-w-100px' />
    ),
    id: 'acoes',
    Cell: (props) => <ActionsCell item={props.data[props.row.index]} />,
  },
]
