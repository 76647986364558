import {useMemo} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {templateesColumns} from './columns/_columns'
import {Template} from '../core/_models'
import {TemplatesListPagination} from '../components/pagination/TemplatesListPagination'
import {KTCardBody} from '../../../../_metronic/helpers'
import { ListLoading } from '../../../components/ListLoading'
import { useQueryResponseData, useQueryResponseLoading } from '../../../components/pageable/query-response.provider'

const TemplatesTable = () => {
  const templatees = useQueryResponseData<Template>()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => templatees.sort((a, b) => (b.id ?? 0) - (a.id ?? 0)), [templatees])
  const columns = useMemo(() => templateesColumns, [])

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_templates'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<Template>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<Template>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    Nenhum registro encontrado
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <TemplatesListPagination />
      {isLoading && <ListLoading />}
    </KTCardBody>
  )
}

export {TemplatesTable}
