import {FC} from 'react'
import { PageTitle } from '../../_metronic/layout/core'

const PrincipalPage: FC = () => (
  <>
  </>
)

const PrincipalWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Principal</PageTitle>
      <PrincipalPage />
    </>
  )
}

export {PrincipalWrapper}
