import { useAuth } from '../../../../../app/modules/auth'
import {MenuItem} from './MenuItem'

export function MenuInner() {
  const {currentUser} = useAuth()
  const isMaster = !!currentUser?.perfil?.master;
  return (
    <>
      <MenuItem title='Principal' to='/principal' />
      <MenuItem title='Contratos' to='/documentos' />
      {isMaster && <MenuItem title='Modelos' to='/templates' />}
      {isMaster && <MenuItem title='Usuários' to='/colaboradores' />}
      {isMaster && <MenuItem title='Perfil' to='/perfil' />}
    </>
  )
}
