import {KTSVG} from '../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
// import {TemplatesListFilter} from './TemplatesListFilter'

const TemplateListToolbar = () => {
  const {setItemIdForUpdate} = useListView()

  const openAddTemplateModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-template-table-toolbar='base'>
      {/* <TemplatesListFilter /> */}

      {/* begin::Add Template */}
      <button type='button' className='btn btn-primary' onClick={openAddTemplateModal}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Adicionar Modelo
      </button>
      {/* end::Add Template */}
    </div>
  )
}

export {TemplateListToolbar}
