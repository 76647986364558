import axios from 'axios'
import {ID, Response} from '../../../../_metronic/helpers'
import {Colaborador, ColaboradoresQueryResponse, PerfilColaborador} from './_models'
import {Pageable} from '../../../components/pageable/pageable.interface'

const API_URL = process.env.REACT_APP_API_BACK_TODOC
const USER_URL = `${API_URL}/usuarios`
const PERFIL_URL = `${API_URL}/perfis`

const buscarColaboradores = (params?: Pageable): Promise<ColaboradoresQueryResponse> => {
  return axios.get(`${USER_URL}/todos`, {params})
}

const buscarColaboradorPorId = (id: ID): Promise<Colaborador | undefined> => {
  return axios.get(`${USER_URL}/${id}`).then((response: Response<Colaborador>) => response.data)
}

const buscarTodosPerfis = (): Promise<Pageable<PerfilColaborador> | undefined> => {
  return axios
    .get(`${PERFIL_URL}/todos`)
    .then((response: Response<Pageable<PerfilColaborador>>) => response.data)
}

const novoColaborador = (colaborador: Colaborador): Promise<Colaborador | undefined> => {
  return axios.put(USER_URL, colaborador).then((response: Response<Colaborador>) => response.data)
}

const atualizarColaborador = (colaborador: Colaborador): Promise<Colaborador | undefined> => {
  return (
    axios
      .post(USER_URL, colaborador)
      // .then((response: AxiosResponse<Response<Colaborador>>) => response.data)
      .then((response: Response<Colaborador>) => response.data)
  )
}

const deletarColaborador = (colaboradorId: ID): Promise<any> => {
  return deletarColaboradoresSelecionados([colaboradorId])
}

const deletarColaboradoresSelecionados = (userIds: Array<ID>): Promise<any> => {
  // const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  // // TODO alterar para chamar como /varios/id,id,id
  // return axios.all(requests)
  return axios.delete(`${USER_URL}`, {
    data: userIds,
  })
}

export {
  buscarColaboradores,
  deletarColaborador,
  deletarColaboradoresSelecionados,
  buscarColaboradorPorId,
  novoColaborador,
  atualizarColaborador,
  buscarTodosPerfis,
}
