import {KTSVG} from '../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
// import {ListFilter} from './ListFilter'

 export function ListToolbar () {
  const {setItemIdForUpdate} = useListView()
  const openAddPerfilModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-perfil-table-toolbar='base'>
      {/* <ListFilter /> */}

      {/* begin::Add Perfil */}
      <button type='button' className='btn btn-primary' onClick={openAddPerfilModal}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Adicionar Perfil
      </button>
      {/* end::Add Perfil */}
    </div>
  )
}
